import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/1.jpg';
import blogd2 from '../../assets/images/newsPub.jpeg';
import { Link } from 'gatsby';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blogd1} alt='' />
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                📢 Admission Alert: Applications Now Open for Lagos University Teaching Hospital (LUTH) 📝
                </h2>
                <p className='blog-one__text'>
                  📣 Attention All Prospective Students! 📣 We are pleased to
                  announce that the application window for admission to Lagos
                  University Teaching Hospital (LUTH) for the upcoming academic
                  year is officially OPEN. <br />As one of the premier institutions of
                  medical education and healthcare in Nigeria, Lagos University
                  Teaching Hospital (LUTH) prides itself on academic excellence,
                  a supportive community, and a commitment to holistic student
                  development. <br /> If you aspire to be part of an institution that
                  values medical innovation, fosters critical thinking, and
                  promotes personal growth, then we encourage you to apply
                  today. <br />Don't miss this opportunity to become a member of our
                  esteemed medical community. Take the first step towards a
                  bright and promising future by submitting your application
                  now.
                  <br />
                  <br />
                  To apply clickon this link to view the various schools <Link to='https://luth.gov.ng/schools-list' >https://luth.gov.ng/schools-list</Link>
                  <br />
                  <br />
                  See below for news update;
                  <img src={blogd2}></img>
                </p>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'>Schools</a>
                </p>
              </div>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Schools Application Open | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
